import React from 'react';
import { Box, Typography } from '@mui/material';

const MetricCard = ({ icon: Icon, title, value, color }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#1a1a1a', // Фон карточки
        borderRadius: '16px',
        padding: 2,
        textAlign: 'center',
        color: '#fff',
        width: '150px', // Фиксированная ширина
        height: '150px', // Фиксированная высота
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
      }}
    >
      <Icon sx={{ fontSize: 32, color }} />
      <Typography variant="subtitle1" sx={{ marginTop: 1, fontSize: '0.8rem' }}>
        {title}
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 1, fontSize: '1.2rem' }}>
        {value}
      </Typography>
    </Box>
  );
};

export default MetricCard;
