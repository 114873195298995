import React from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';

function ServerForm({ serverData, handleChange, handleSubmit, onCancel }) {
  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ width: '300px', margin: '0 auto' }}>
      <Typography variant="h6" gutterBottom>
        Данные сервера
      </Typography>
      <TextField
        label="IP-адрес сервера"
        variant="outlined"
        name="ip_address"
        value={serverData.ip_address}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        label="Порт"
        variant="outlined"
        name="port"
        value={serverData.port}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        label="Логин"
        variant="outlined"
        name="login"
        value={serverData.login}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
      />
      <TextField
        label="Пароль"
        type="password"
        variant="outlined"
        name="password"
        value={serverData.password}
        onChange={handleChange}
        required
        fullWidth
        margin="normal"
      />
      <Box mt={3} display="flex" justifyContent="center" gap={2}>
        <Button onClick={onCancel} color="secondary" sx={{ minWidth: 120, textTransform: 'none' }}>
          Отменить
        </Button>
        <Button type="submit" variant="contained" color="primary" sx={{ minWidth: 120, textTransform: 'none' }}>
          Сохранить
        </Button>
      </Box>
    </Box>
  );
}

export default ServerForm;
