import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Stack,
  Alert,
  Drawer,
} from '@mui/material';
import ServerList from '../components/ServerList';
import ServerForm from '../components/ServerForm';
import ServerDialog from '../components/ServerDialog';
import { fetchServers, fetchActiveServer, setActiveServer, createServer, deleteServer } from '../services/serverService';

function Profile() {
  const [servers, setServers] = useState([]);
  const [activeServer, setActiveServerState] = useState(null);
  const [showAddServerDialog, setShowAddServerDialog] = useState(false);
  const [showServerDialog, setShowServerDialog] = useState(false);
  const [serverData, setServerData] = useState({ ip_address: '', port: '', login: '', password: '' });
  const [error, setError] = useState(null);

  useEffect(() => {
    const initializeData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Токен не найден, необходимо авторизоваться');
        setError('Токен не найден, необходимо авторизоваться');
        return;
      }

      try {
        const serversList = await fetchServers(token);
        setServers(serversList);

        const activeServerData = await fetchActiveServer(token);
        setActiveServerState(activeServerData);
      } catch (error) {
        console.error('Ошибка при инициализации данных:', error);
      }
    };

    initializeData();
  }, []);

  const handleServerClick = async (server) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Токен не найден, необходимо авторизоваться');
      return;
    }

    if (activeServer?.id === server.id) {
      setServerData(server);
      setShowServerDialog(true);
    } else {
      try {
        await setActiveServer(server.id, token);
        const updatedActiveServer = await fetchActiveServer(token);
        setActiveServerState(updatedActiveServer);
        setError(null);
      } catch (error) {
        console.error('Ошибка при установке активного сервера:', error);
        setError('Не удалось установить активный сервер. Попробуйте снова.');
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setServerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Токен не найден, необходимо авторизоваться');
      return;
    }

    try {
      const newServer = await createServer(serverData, token);
      console.log('Сохранение сервера:', newServer);
      setShowAddServerDialog(false);
      setServerData({ ip_address: '', port: '', login: '', password: '' });

      // Реинициализируем список серверов после добавления
      const serversList = await fetchServers(token);
      setServers(serversList);

      // Устанавливаем новый сервер как активный
      await setActiveServer(newServer.id, token);
      setActiveServerState(newServer);

      setError(null);
    } catch (error) {
      console.error('Ошибка при сохранении сервера:', error);
      setError('Ошибка при сохранении сервера. Попробуйте снова.');
    }
  };

  const handleCancel = () => {
    setShowAddServerDialog(false);
    setServerData({ ip_address: '', port: '', login: '', password: '' });
  };

  const handleDelete = async (serverId) => {
    console.log('Удаление сервера начато, ID сервера:', serverId);
    const token = localStorage.getItem('token');
    if (!token) {
      setError('Токен не найден, необходимо авторизоваться');
      return;
    }

    try {
      await deleteServer(serverId, token);
      console.log('Сервер удален, ID:', serverId);

      // Обновляем список серверов после удаления
      const serversList = await fetchServers(token);
      setServers(serversList);

      setShowServerDialog(false);
      setError(null);
    } catch (error) {
      console.error('Ошибка при удалении сервера:', error);
      setError('Ошибка при удалении сервера. Попробуйте снова.');
    }
  };

  return (
    <Box       
    mt={4}
    sx={{
      // Добавляем нижний отступ, чтобы меню не закрывало контент
      paddingBottom: '80px',
    }}
  >
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      <Stack
        direction="column"
        spacing={2}
        mb={4}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        <ServerList
          servers={servers}
          activeServer={activeServer}
          onServerClick={handleServerClick}
        />
        <Button
          variant="outlined"
          onClick={() => setShowAddServerDialog(true)}
          sx={{ width: '75%' }}
        >
          Добавить сервер
        </Button>
      </Stack>

      {/* Диалог для добавления сервера */}
      <Drawer
        anchor="top"
        open={showAddServerDialog}
        onClose={handleCancel}
        sx={{
          '.MuiDrawer-paper': {
            width: '90%',
            maxWidth: '400px',
            margin: '0 auto',
            padding: '20px',
            borderRadius: 2,
          },
        }}
      >
        <ServerForm
          serverData={serverData}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      </Drawer>

      {/* Диалог с информацией о сервере */}
      <ServerDialog
        server={serverData}
        open={showServerDialog}
        onClose={() => setShowServerDialog(false)}
        onEdit={() => {
          setShowServerDialog(false);
          setShowAddServerDialog(true);
        }}
        onDelete={() => {
          console.log('Кнопка удаления нажата, ID сервера:', serverData.id);
          handleDelete(serverData.id);
        }}
      />
    </Box>
  );
}

export default Profile;
