import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { Box, BottomNavigation, BottomNavigationAction, Typography, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BarChartIcon from '@mui/icons-material/BarChart';
import Profile from './pages/Profile';
import Dashboard from './pages/Dashboard';
import Metrics from './pages/Metrics';
import darkTheme from './theme';
import { handleTelegramAuth, verifyToken, isTokenExpired } from './services/authService';

function App() {
  const [userData, setUserData] = useState(null);
  const [currentTab, setCurrentTab] = useState('/profile');
  const [authError, setAuthError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      try {
        const params = new URLSearchParams(window.location.hash.substring(1));
        const tgWebAppData = params.get('tgWebAppData');

        if (tgWebAppData) {
          const accessToken = await handleTelegramAuth(tgWebAppData);
          if (accessToken) {
            const data = await verifyToken(accessToken);
            if (data) {
              setUserData(data);
              setLoading(false);
              return;
            } else {
              localStorage.removeItem('token');
              setAuthError(true);
              setLoading(false);
              return;
            }
          } else {
            setAuthError(true);
            setLoading(false);
            return;
          }
        } else {
          const storedToken = localStorage.getItem('token');
          if (storedToken) {
            if (!isTokenExpired(storedToken)) {
              const data = await verifyToken(storedToken);
              if (data) {
                setUserData(data);
                setLoading(false);
                return;
              } else {
                localStorage.removeItem('token');
                setAuthError(true);
                setLoading(false);
                return;
              }
            } else {
              localStorage.removeItem('token');
              setAuthError(true);
              setLoading(false);
              return;
            }
          } else {
            setAuthError(true);
            setLoading(false);
          }
        }
      } catch {
        setAuthError(true);
        setLoading(false);
      }
    };

    initAuth();
  }, []);

  if (loading) {
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress color="primary" />
        </Box>
      </ThemeProvider>
    );
  }

  if (authError) {
    return (
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Typography variant="h6" color="error" align="center" sx={{ marginTop: '20%' }}>
          Ошибка авторизации. Попробуйте перезапустить приложение через Telegram.
        </Typography>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        {/* Основной контент приложения */}
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            // Можно убрать paddingBottom, если вы не хотите дополнительного отступа
            // paddingBottom: '80px',
          }}
        >
          <Routes>
            <Route path="/profile" element={<Profile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/metrics" element={<Metrics />} />
            <Route path="*" element={<Navigate to="/profile" />} />
          </Routes>
        </Box>

        {/* Плавающее меню */}
        <Box
          sx={{
            position: 'fixed',
            bottom: 16,              // Отступ от нижней границы экрана
            left: '50%',             // Центрируем по горизонтали
            transform: 'translateX(-50%)',  // Сдвиг, чтобы центр совпадал со серединой
            zIndex: 10,
          }}
        >
          <BottomNavigation
            value={currentTab}
            onChange={(event, newValue) => {
              setCurrentTab(newValue);
            }}
            showLabels={false}
            sx={{
              borderRadius: '50px',
              padding: '8px 16px',
              backgroundColor: '#2a2a2a',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              overflow: 'hidden',
            }}
          >
            <BottomNavigationAction
              icon={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 7px',
                    color: '#ffffff',
                  }}
                >
                  <SettingsIcon sx={{ color: '#ffffff', fontSize: 32 }} />
                  {currentTab === '/profile' && (
                    <Typography sx={{ marginLeft: 1, color: '#ffffff' }}>Настройки</Typography>
                  )}
                </Box>
              }
              component={Link}
              to="/profile"
              value="/profile"
              sx={{
                minWidth: 'fit-content',
                padding: 0,
              }}
            />
            <BottomNavigationAction
              icon={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    color: '#ffffff',
                  }}
                >
                  <DashboardIcon sx={{ color: '#ffffff', fontSize: 32 }} />
                  {currentTab === '/dashboard' && (
                    <Typography sx={{ marginLeft: 1, color: '#ffffff' }}>События</Typography>
                  )}
                </Box>
              }
              component={Link}
              to="/dashboard"
              value="/dashboard"
              sx={{
                minWidth: 'fit-content',
                padding: 0,
              }}
            />
            <BottomNavigationAction
              icon={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 16px',
                    color: '#ffffff',
                  }}
                >
                  <BarChartIcon sx={{ color: '#ffffff', fontSize: 32 }} />
                  {currentTab === '/metrics' && (
                    <Typography sx={{ marginLeft: 1, color: '#ffffff' }}>Выручка</Typography>
                  )}
                </Box>
              }
              component={Link}
              to="/metrics"
              value="/metrics"
              sx={{
                minWidth: 'fit-content',
                padding: 0,
              }}
            />
          </BottomNavigation>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
