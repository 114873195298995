import React from 'react';
import { Box } from '@mui/material';
import MetricCard from './MetricCard';
import { CurrencyRuble, Discount, Percent, ShoppingCart } from '@mui/icons-material';

const MetricsCardsContainer = ({ filteredData }) => {
  if (!filteredData) return <div>Данные не найдены</div>;

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)', // Всегда 2 карточки в строке
        gap: '16px', // Расстояние между карточками
        justifyContent: 'center', // Центрируем карточки
        maxWidth: '100px', // Ограничиваем ширину контейнера
        margin: '0 auto', // Центрируем контейнер
      }}
    >
      <MetricCard
        icon={CurrencyRuble}
        title="Сумма со скидкой"
        value={(filteredData.totalSum || 0).toLocaleString('ru-RU', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
        color="#2196F3"
      />
      <MetricCard
        icon={Discount}
        title="Сумма скидки"
        value={(filteredData.totalDiscount || 0).toLocaleString('ru-RU', { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
        color="#FF9800"
      />
      <MetricCard
        icon={Percent}
        title="Процент скидки"
        value={`${(filteredData.discountPercent || 0).toFixed(2)}%`}
        color="#ff3665"
      />
      <MetricCard
        icon={ShoppingCart}
        title="Кол-во заказов"
        value={filteredData.totalOrders || 0}
        color="#8BC34A"
      />
    </Box>
  );
};

export default MetricsCardsContainer;
