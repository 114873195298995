import React from 'react';
import { Box } from '@mui/material';

const StyledContainer = ({ children }) => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(to bottom, #6a11cb, #2575fc)', // Градиент
        borderRadius: '16px', // Скругленные углы
        padding: 3, // Внутренние отступы
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Тень
        minHeight: '300px', // Минимальная высота
        maxWidth: '1200px', // Увеличиваем максимальную ширину
        width: '100%', // Занимает 95% ширины экрана
        margin: '0 auto', // Центрируем контейнер
        display: 'flex', // Flexbox
        flexDirection: 'column', // Вертикальная ориентация
        justifyContent: 'space-between', // Контент распределён сверху и снизу
        alignItems: 'center', // Центрирование по горизонтали
      }}
    >
      {children}
    </Box>
  );
};

export default StyledContainer;
