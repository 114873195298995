import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

function ServerDialog({ server, open, onClose, onEdit, onDelete }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Информация о сервере</DialogTitle>
      <DialogContent>
        <Typography>IP-адрес: {server?.ip_address}</Typography>
        <Typography>Порт: {server?.port}</Typography>
        <Typography>Логин: {server?.login}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onEdit} color="primary">
          Редактировать
        </Button>
        <Button onClick={onDelete} color="error">
          Удалить
        </Button>
        <Button onClick={onClose} color="secondary">
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ServerDialog;
