// src/services/serverService.js

import axios from 'axios';

const api = axios.create({
  baseURL: 'https://report.pro-resto.ru', // URL вашего API
});

// Общая функция для обработки успешных ответов API
const handleResponse = (response, key) => {
  if (response.status === 200 && response.data[key]) {
    return typeof response.data[key] === 'string' ? JSON.parse(response.data[key]) : response.data[key];
  }
  throw new Error(response.data.message || `Ошибка при обработке ответа на ${key}`);
};

// Общая функция для обработки ошибок API
const handleError = (error, actionDescription) => {
  console.error(`Ошибка при выполнении запроса (${actionDescription}):`, error.message);
  throw error;
};

// Получение списка серверов
export const fetchServers = async (token) => {
  try {
    const response = await api.get('/servers/list', {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return handleResponse(response, 'servers');
  } catch (error) {
    handleError(error, 'получение списка серверов');
  }
};

// Получение активного сервера
export const fetchActiveServer = async (token) => {
  try {
    const response = await api.get('/servers/active', {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    return handleResponse(response, 'active_server');
  } catch (error) {
    handleError(error, 'получение активного сервера');
  }
};

// Установка активного сервера
export const setActiveServer = async (serverId, token) => {
  try {
    const response = await api.post(`/servers/set_active/${serverId}`, {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Ошибка при установке активного сервера (ID: ${serverId}): ${response.data}`);
    }
  } catch (error) {
    handleError(error, `установка активного сервера (ID: ${serverId})`);
  }
};

// Создание сервера
export const createServer = async (serverData, token) => {
  try {
    const response = await api.post('/servers/create', serverData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Ошибка при добавлении сервера: ${response.data}`);
    }
  } catch (error) {
    handleError(error, 'создание сервера');
  }
};

// Обновление сервера
export const updateServer = async (serverId, serverData, token) => {
  try {
    const response = await api.put(`/servers/update/${serverId}`, serverData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Ошибка при редактировании сервера (ID: ${serverId}): ${response.data}`);
    }
  } catch (error) {
    handleError(error, `редактирование сервера (ID: ${serverId})`);
  }
};

// Удаление сервера
export const deleteServer = async (serverId, token) => {
  try {
    const response = await api.delete(`/servers/delete/${serverId}`, {
      headers: { 'Authorization': `Bearer ${token}` }
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`Ошибка при удалении сервера (ID: ${serverId}): ${response.data}`);
    }
  } catch (error) {
    handleError(error, `удаление сервера (ID: ${serverId})`);
  }
};
