import axios from 'axios';

const apiHeaders = (token) => ({
  headers: {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});

export const fetchFilteredEvents = async (token, activeServer, dateRange) => {
  if (!activeServer || !dateRange.from || !dateRange.to) return;

  const eventTypes = ['orderDeleted', 'orderCancelPrecheque', 'orderPrepayReturned', 'deletedPrintedItems'];
  const fromTime = `${dateRange.from}T00:00:00.000`;
  const toTime = `${dateRange.to}T23:59:59.999`;

  try {
    const response = await axios.post(
      `https://report.pro-resto.ru/data/filtered_events?from_time=${fromTime}&to_time=${toTime}`,
      eventTypes,
      apiHeaders(token)
    );

    return response.status === 200 && response.data.filtered_events ? response.data.filtered_events : [];
  } catch (error) {
    console.error('Ошибка при выполнении запроса на получение событий:', error.message);
    throw error;
  }
};

export const fetchRevenueData = async (token, activeServer, dateRange) => {
  if (!activeServer || !dateRange.from || !dateRange.to) return;

  const url = `https://report.pro-resto.ru/data/revenue?from_date=${dateRange.from}&to_date=${dateRange.to}`;

  try {
    const response = await axios.get(url, apiHeaders(token));
    return response.status === 200 && response.data.revenue ? response.data.revenue : [];
  } catch (error) {
    console.error('Ошибка при выполнении запроса на получение данных о выручке:', error.message);
    throw error;
  }
};

export const fetchSalesByPaymentType = async (token, activeServer, dateRange) => {
  if (!activeServer || !dateRange.from || !dateRange.to) return;

  const url = `https://report.pro-resto.ru/data/sales_by_payment?from_date=${dateRange.from}&to_date=${dateRange.to}`;

  try {
    const response = await axios.get(url, apiHeaders(token));
    return response.status === 200 && response.data.sales_data ? response.data.sales_data : [];
  } catch (error) {
    console.error('Ошибка при выполнении запроса на получение данных о продажах по типам оплат:', error.message);
    throw error;
  }
};
