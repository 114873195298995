import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { format, startOfWeek, startOfMonth, startOfYear, subDays, subWeeks, subMonths, subYears } from 'date-fns';

const DateRangePicker = ({ onDateChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState('Сегодня'); // По умолчанию "Сегодня"

  // Открыть меню
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Закрыть меню
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Опции и их вычисления
  const dateOptions = [
    {
      label: 'Сегодня',
      range: {
        from: format(new Date(), 'yyyy-MM-dd'),
        to: format(new Date(), 'yyyy-MM-dd'),
      },
    },
    {
      label: 'Вчера',
      range: {
        from: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
        to: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
      },
    },
    {
      label: 'Текущая неделя',
      range: {
        from: format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
        to: format(new Date(), 'yyyy-MM-dd'),
      },
    },
    {
      label: 'Прошлая неделя',
      range: {
        from: format(startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
        to: format(subDays(startOfWeek(new Date(), { weekStartsOn: 1 }), 1), 'yyyy-MM-dd'),
      },
    },
    {
      label: 'Текущий месяц',
      range: {
        from: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        to: format(new Date(), 'yyyy-MM-dd'),
      },
    },
    {
      label: 'Прошлый месяц',
      range: {
        from: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
        to: format(subDays(startOfMonth(new Date()), 1), 'yyyy-MM-dd'),
      },
    },
    {
      label: 'Текущий год',
      range: {
        from: format(startOfYear(new Date()), 'yyyy-MM-dd'),
        to: format(new Date(), 'yyyy-MM-dd'),
      },
    },
    {
      label: 'Прошлый год',
      range: {
        from: format(startOfYear(subYears(new Date(), 1)), 'yyyy-MM-dd'),
        to: format(subDays(startOfYear(new Date()), 1), 'yyyy-MM-dd'),
      },
    },
  ];

  // Выбор опции
  const handleSelect = (option) => {
    setSelectedLabel(option.label); // Обновляем текст кнопки
    onDateChange(option.range); // Вызываем функцию-обработчик
    handleClose(); // Закрываем меню
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleClick}
        sx={{
          backgroundColor: '#333',
          color: '#fff',
          borderRadius: '16px',
          textTransform: 'none',
          padding: '8px 16px',
          fontSize: '1rem',
          width: '323px',
        }}
      >
        {selectedLabel}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom', // Открытие снизу кнопки
            horizontal: 'center', // Центрирование по горизонтали
        }}
        transformOrigin={{
            vertical: 'top', // Верхняя часть меню выравнивается
            horizontal: 'center', // Центрирование по горизонтали
        }}
        PaperProps={{
            style: {
            borderRadius: '16px',
            minWidth: '200px',
            },
        }}
        >
        {dateOptions.map((option) => (
            <MenuItem
            key={option.label}
            onClick={() => handleSelect(option)}
            sx={{
                padding: '8px 16px',
                fontSize: '0.9rem',
            }}
            >
            {option.label}
            </MenuItem>
        ))}
        </Menu>
    </Box>
  );
};

export default DateRangePicker;
