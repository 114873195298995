import { useState, useEffect } from 'react';
import { fetchFilteredEvents } from '../services/dataService';

export const useEvents = (token, server, dateRange) => {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [eventCounts, setEventCounts] = useState({
    deleteOrder: 0,
    cancelPrecheque: 0,
    prepayReturn: 0,
    deleteOrderItems: 0,
  });

  useEffect(() => {
    if (!server || !dateRange.from || !dateRange.to) return;

    const fetchEvents = async () => {
      setLoading(true);
      try {
        const fetchedEvents = await fetchFilteredEvents(token, server, dateRange);
        const counts = {
          deleteOrder: 0,
          cancelPrecheque: 0,
          prepayReturn: 0,
          deleteOrderItems: 0,
        };

        fetchedEvents.forEach((event) => {
          switch (event.type) {
            case 'orderDeleted':
              counts.deleteOrder++;
              break;
            case 'orderCancelPrecheque':
              counts.cancelPrecheque++;
              break;
            case 'orderPrepayReturned':
              counts.prepayReturn++;
              break;
            case 'deletedPrintedItems':
              counts.deleteOrderItems++;
              break;
            default:
              break;
          }
        });

        setEvents(fetchedEvents);
        setEventCounts(counts);
      } catch (error) {
        console.error('Ошибка при загрузке событий:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [token, server, dateRange]);

  return { loading, events, eventCounts };
};
