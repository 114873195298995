import React, { useState, useEffect } from 'react';
import { Box, Typography, Snackbar } from '@mui/material';
import StyledContainer from '../components/StyledContainer';
import DateRangePickerStyled from '../components/DateRangePickerStyled';
import EventCards from '../components/EventCards';
import EventDetailsDialog from '../components/EventDetailsDialog';
import { fetchServers, fetchActiveServer } from '../services/serverService';
import { useEvents } from '../hooks/useEvents';

function Dashboard() {
  const getTodayDate = () => new Date().toISOString().split('T')[0];

  const [dateRange, setDateRange] = useState({ from: getTodayDate(), to: getTodayDate() });
  const [activeServer, setActiveServerState] = useState(null);
  const [servers, setServers] = useState([]);
  const [showEventDetailsDialog, setShowEventDetailsDialog] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState('');
  const [error, setError] = useState('');
  const [logs, setLogs] = useState([]); // Для отображения логов

  const token = localStorage.getItem('token');

  // Используем кастомный хук для событий
  const { loading, events, eventCounts } = useEvents(token, activeServer, dateRange);

  useEffect(() => {
    const initializeData = async () => {
      if (!token) {
        setError('Токен не найден. Авторизуйтесь снова.');
        addLog('Токен отсутствует, данные не загружены.');
        return;
      }

      try {
        const server = await fetchActiveServer(token);
        setActiveServerState(server);
        const serversList = await fetchServers(token);
        setServers(serversList);

        addLog('Серверы успешно загружены.');
      } catch (err) {
        setError('Ошибка при загрузке данных сервера.');
        addLog(`Ошибка при инициализации данных: ${err.message}`);
      }
    };

    initializeData();
  }, [token]);

  // Добавление логов
  const addLog = (message) => {
    setLogs((prevLogs) => [...prevLogs, `${new Date().toLocaleTimeString()}: ${message}`]);
  };

  const handleEventDetailsClick = (eventType) => {
    const filteredEvents = events.filter((event) => event.type === eventType);

    if (filteredEvents.length > 0) {
      setSelectedEventType(eventType);
      setShowEventDetailsDialog(true);
      addLog(`События типа "${eventType}" успешно выбраны для отображения.`);
    } else {
      setError(`Нет данных для события типа: ${eventType}`);
      addLog(`События типа "${eventType}" отсутствуют.`);
    }
  };

  const handleDateChange = (range) => {
    setDateRange(range);
    addLog(`Диапазон дат изменён: с ${range.from} по ${range.to}`);
  };

  return (
    <Box sx={{ padding: 3, paddingBottom: '180px'}}>
      <StyledContainer>
        {/* Карточки событий */}
        <EventCards
          eventCounts={eventCounts}
          loading={loading}
          handleEventDetailsClick={handleEventDetailsClick}
        />

        {/* Компонент выбора дат */}
        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
          <DateRangePickerStyled onDateChange={handleDateChange} />
        </Box>
      </StyledContainer>

      {/* Диалог с деталями событий */}
      <EventDetailsDialog
        showEventDetailsDialog={showEventDetailsDialog}
        setShowEventDetailsDialog={(open) => {
          setShowEventDetailsDialog(open);
          if (!open) {
            setSelectedEventType('');
          }
        }}
        selectedEventType={selectedEventType}
        selectedEventDetails={events.filter((event) => event.type === selectedEventType)}
        ATTRIBUTE_ORDER={[
          'orderNum',
          'openTime',
          'orderSumAfterDiscount',
          'isBanquet',
          'waiter',
          'user',
          'dishes',
          'comment',
        ]}
      />
    </Box>
  );
}

export default Dashboard;
