import axios from 'axios';
import jwt_decode from 'jwt-decode';

const api = axios.create({
  baseURL: 'https://report.pro-resto.ru', // URL вашего API
});

// Перехватчик для автоматического добавления Authorization
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Перехватчик для обработки ошибок авторизации
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.error('Access token истёк или недействителен. Перенаправление на страницу входа.');
      // Перенаправляем на страницу логина
      window.location.href = '/login'; // Убедитесь, что этот маршрут существует
    }
    return Promise.reject(error);
  }
);

/**
 * Функция для обработки авторизации через Telegram.
 * Отправляет данные Telegram WebApp API целиком на сервер и сохраняет токен доступа.
 * 
 * @param {string} tgWebAppData - Строка данных из Telegram WebApp API.
 * @returns {Promise<string>} Возвращает access_token.
 */
export const handleTelegramAuth = async (tgWebAppData) => {
  try {
    const response = await api.post('/auth/telegram', { tgWebAppData });

    if (response.status === 200) {
      const { access_token } = response.data;

      if (access_token) {
        // Сохраняем токен
        localStorage.setItem('token', access_token);
        console.log('Авторизация успешна, access_token сохранён.');
        return access_token;
      } else {
        console.error('Access token не получен. Ответ сервера:', response.data);
        throw new Error('Access token не получен.');
      }
    } else {
      console.error('Ошибка авторизации:', response.data);
      throw new Error('Ошибка авторизации на сервере.');
    }
  } catch (error) {
    console.error('Ошибка выполнения запроса:', error);
    throw error;
  }
};

/**
 * Проверка срока действия токена.
 * 
 * @param {string} token - JWT токен для проверки.
 * @returns {boolean} Возвращает true, если токен истёк, иначе false.
 */
export const isTokenExpired = (token) => {
  try {
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000; // Время в секундах
    return decoded.exp < currentTime;
  } catch (error) {
    console.error('Ошибка при проверке срока действия токена:', error);
    return true;
  }
};

export default api;

export const verifyToken = async (accessToken) => {
  try {
    const response = await api.get('/me', {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    if (response.status === 200) {
      return response.data; // Возвращает {id, username}
    }
    return null;
  } catch {
    return null;
  }
};
