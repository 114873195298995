import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

function EventCards({ eventCounts, loading, handleEventDetailsClick }) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)', // Три колонки в ряду
        gap: 1, // Расстояние между карточками
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 1,
      }}
    >
      {/* Карточка: Удаление заказа */}
      <Box
        onClick={() => handleEventDetailsClick('orderDeleted')}
        sx={{
          backgroundColor: '#1a1a1a',
          padding: 2,
          borderRadius: 2,
          textAlign: 'center',
          color: '#fff',
          cursor: 'pointer',
          boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
          maxWidth: '120px',
        }}
      >
        <Typography sx={{ fontSize: '0.8rem' }}>Удаление заказа</Typography>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Typography variant="h5" sx={{ fontSize: '1.2rem' }}>
            {eventCounts.deleteOrder || 0}
          </Typography>
        )}
      </Box>

      {/* Карточка: Отмена пречека */}
      <Box
        onClick={() => handleEventDetailsClick('orderCancelPrecheque')}
        sx={{
          backgroundColor: '#1a1a1a',
          padding: 2,
          borderRadius: 2,
          textAlign: 'center',
          color: '#fff',
          cursor: 'pointer',
          boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
          maxWidth: '120px',
        }}
      >
        <Typography sx={{ fontSize: '0.8rem' }}>Отмена пречека</Typography>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Typography variant="h5" sx={{ fontSize: '1.2rem' }}>
            {eventCounts.cancelPrecheque || 0}
          </Typography>
        )}
      </Box>

      {/* Карточка: Возврат предоплаты */}
      <Box
        onClick={() => handleEventDetailsClick('orderPrepayReturned')}
        sx={{
          backgroundColor: '#1a1a1a',
          padding: 2,
          borderRadius: 2,
          textAlign: 'center',
          color: '#fff',
          cursor: 'pointer',
          boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
          maxWidth: '120px',
        }}
      >
        <Typography sx={{ fontSize: '0.8rem' }}>Возврат предоплаты</Typography>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Typography variant="h5" sx={{ fontSize: '1.2rem' }}>
            {eventCounts.prepayReturn || 0}
          </Typography>
        )}
      </Box>

      {/* Гифка */}
      <Box
        sx={{
          gridColumn: '1 / 4', // Занимает всю ширину
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginY: 2, // Отступы сверху и снизу
        }}
      >
        <a
          href="https://pro-resto.ru/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/audit.gif?${Date.now()}`} // Уникальный URL для обхода кэширования
            alt="Audit Animation"
            style={{
              maxWidth: '100%',
              height: 'auto',
              borderRadius: '8px',
              cursor: 'pointer',
            }}
          />
        </a>
      </Box>

      {/* Карточка: Удаление отпечатанных позиций */}
      <Box
        onClick={() => handleEventDetailsClick('deletedPrintedItems')}
        sx={{
          gridColumn: '1 / 4', // Занимает всю ширину
          backgroundColor: '#1a1a1a',
          padding: 2,
          borderRadius: 2,
          textAlign: 'center',
          color: '#fff',
          cursor: 'pointer',
          boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
        }}
      >
        <Typography sx={{ fontSize: '0.8rem' }}>Удаление отпечатанных позиций</Typography>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Typography variant="h5" sx={{ fontSize: '1.2rem' }}>
            {eventCounts.deleteOrderItems || 0}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default EventCards;
