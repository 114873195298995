import React from 'react';
import {
  Button,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

function ServerList({ servers, activeServer, onServerClick }) {
  return (
    <Stack spacing={2} sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
      {servers.map((server) => (
        <Button
          key={server.id}
          variant="contained"
          onClick={() => onServerClick(server)}
          sx={{
            width: '75%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor:
              activeServer?.id === server.id ? '#4caf50' : 'primary.main', // Изменение цвета для активного сервера
          }}
        >
          <Typography variant="button">{server.server_name}</Typography>
          {activeServer?.id === server.id && <CheckIcon />}
        </Button>
      ))}
    </Stack>
  );
}

export default ServerList;
