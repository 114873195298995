import React, { useState, useEffect } from 'react';
import { Box, Typography, Menu, MenuItem, Button, Alert } from '@mui/material';
import StyledContainer from '../components/StyledContainer';
import DateRangePickerStyled from '../components/DateRangePickerStyled';
import MetricsCardsContainer from '../components/MetricsCardsContainer';
import { fetchSalesByPaymentType } from '../services/dataService';
import { fetchActiveServer } from '../services/serverService';

const Metrics = () => {
  const [dateRange, setDateRange] = useState({
    from: new Date().toISOString().split('T')[0],
    to: new Date().toISOString().split('T')[0],
  });
  const [salesData, setSalesData] = useState(null);
  const [activeServer, setActiveServerState] = useState(null);
  const [error, setError] = useState(null);

  const [payTypesMenuAnchor, setPayTypesMenuAnchor] = useState(null);
  const [restorauntGroupMenuAnchor, setRestorauntGroupMenuAnchor] = useState(null);
  const [selectedPayType, setSelectedPayType] = useState('Все');
  const [selectedRestorauntGroup, setSelectedRestorauntGroup] = useState('Все');

  const token = localStorage.getItem('token');

  useEffect(() => {
    const initializeData = async () => {
      if (!token) {
        setError('Токен отсутствует. Авторизуйтесь снова.');
        return;
      }

      try {
        const server = await fetchActiveServer(token);
        if (!server) {
          setError('Активный сервер не найден. Выберите сервер в профиле.');
          return;
        }

        setActiveServerState(server);

        const data = await fetchSalesByPaymentType(token, server, dateRange);
        setSalesData(data);
        setError(null);
      } catch (err) {
        setError(`Ошибка при загрузке данных: ${err.message}`);
      }
    };

    initializeData();
  }, [token, dateRange]);

  const getFilteredData = () => {
    if (!salesData) return null;

    console.log("Исходные данные (salesData):", salesData);
    console.log("Выбранный тип оплаты (selectedPayType):", selectedPayType);
    console.log("Выбранное подразделение (selectedRestorauntGroup):", selectedRestorauntGroup);

    const filteredDetails = Object.values(salesData).flatMap(item =>
      item.details.filter(detail => {
        const matchesPayType =
          selectedPayType === 'Все' || detail.PayTypes === selectedPayType;
        const matchesRestorauntGroup =
          selectedRestorauntGroup === 'Все' || detail.RestorauntGroup === selectedRestorauntGroup;
        return matchesPayType && matchesRestorauntGroup;
      })
    );

    console.log("Отфильтрованные данные (filteredDetails):", filteredDetails);

    if (filteredDetails.length === 0) {
      return { totalSum: 0, totalDiscount: 0, totalOrders: 0, discountPercent: 0 };
    }

    let totalSum = 0;
    let totalDiscount = 0;
    let totalOrders = 0;

    for (const detail of filteredDetails) {
      totalSum += detail.DishDiscountSumInt || 0;
      totalDiscount += detail.DiscountSum || 0;
      // Предполагаем, что UniqOrderId - это количество заказов
      totalOrders += detail.UniqOrderId || 0;
    }

    const discountPercent = totalSum > 0 ? (totalDiscount / totalSum) * 100 : 0;

    return { totalSum, totalDiscount, totalOrders, discountPercent };
  };

  // Получаем полный список всех типов оплат и подразделений из details
  const getAllOptions = () => {
    if (!salesData) return { allPayTypes: [], allGroups: [] };

    const allPayTypesSet = new Set();
    const allGroupsSet = new Set();

    Object.values(salesData).forEach(item => {
      if (Array.isArray(item.details)) {
        item.details.forEach(detail => {
          if (detail.PayTypes) allPayTypesSet.add(detail.PayTypes);
          if (detail.RestorauntGroup) allGroupsSet.add(detail.RestorauntGroup);
        });
      }
    });

    return {
      allPayTypes: ['Все', ...Array.from(allPayTypesSet)],
      allGroups: ['Все', ...Array.from(allGroupsSet)]
    };
  };

  const getFilteredPayTypes = () => {
    if (!salesData) return [];
    if (selectedRestorauntGroup === 'Все') {
      return getAllOptions().allPayTypes;
    }

    const payTypesSet = new Set();
    Object.values(salesData).forEach(item => {
      if (Array.isArray(item.details)) {
        item.details.forEach(detail => {
          const matchesGroup =
            selectedRestorauntGroup === 'Все' || detail.RestorauntGroup === selectedRestorauntGroup;
          if (matchesGroup && detail.PayTypes) {
            payTypesSet.add(detail.PayTypes);
          }
        });
      }
    });

    return ['Все', ...Array.from(payTypesSet)];
  };

  const getFilteredGroups = () => {
    if (!salesData) return [];
    if (selectedPayType === 'Все') {
      return getAllOptions().allGroups;
    }

    const groupsSet = new Set();
    Object.values(salesData).forEach(item => {
      if (Array.isArray(item.details)) {
        item.details.forEach(detail => {
          const matchesPayType =
            selectedPayType === 'Все' || detail.PayTypes === selectedPayType;
          if (matchesPayType && detail.RestorauntGroup) {
            groupsSet.add(detail.RestorauntGroup);
          }
        });
      }
    });

    return ['Все', ...Array.from(groupsSet)];
  };

  const handlePayTypesMenuOpen = (event) => setPayTypesMenuAnchor(event.currentTarget);
  const handlePayTypesMenuClose = () => setPayTypesMenuAnchor(null);

  const handleRestorauntGroupMenuOpen = (event) => setRestorauntGroupMenuAnchor(event.currentTarget);
  const handleRestorauntGroupMenuClose = () => setRestorauntGroupMenuAnchor(null);

  const handlePayTypeSelect = (payType) => {
    setSelectedPayType(payType);
    setPayTypesMenuAnchor(null);
  };

  const handleRestorauntGroupSelect = (restorauntGroup) => {
    setSelectedRestorauntGroup(restorauntGroup);
    setRestorauntGroupMenuAnchor(null);
  };

  const handleDateChange = (range) => {
    setDateRange(range);
  };

  return (
    <Box sx={{ padding: 3 }}>
      {error && (
        <Alert severity="error" onClose={() => setError(null)} sx={{ marginBottom: 2 }}>
          {error}
        </Alert>
      )}
      <StyledContainer>
        {salesData ? (
          <MetricsCardsContainer filteredData={getFilteredData()} />
        ) : (
          <Typography sx={{ color: '#fff', textAlign: 'center' }}>
            {error ? '' : 'Загрузка данных...'}
          </Typography>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            marginTop: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={handlePayTypesMenuOpen}
            sx={{
              backgroundColor: '#333',
              color: '#fff',
              textTransform: 'none',
              borderRadius: '16px',
              padding: '8px 16px',
              fontSize: 'clamp(0.8rem, 1.5vw, 1rem)',
              width: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {selectedPayType === 'Все' ? 'Типы оплат' : selectedPayType}
          </Button>

          <Button
            variant="contained"
            onClick={handleRestorauntGroupMenuOpen}
            sx={{
              backgroundColor: '#333',
              color: '#fff',
              textTransform: 'none',
              borderRadius: '16px',
              padding: '8px 16px',
              fontSize: 'clamp(0.8rem, 1.5vw, 1rem)',
              width: '150px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {selectedRestorauntGroup === 'Все' ? 'Подразделение' : selectedRestorauntGroup}
          </Button>
        </Box>

        <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
          <DateRangePickerStyled onDateChange={handleDateChange} />
        </Box>

        {/* Меню типов оплат */}
        <Menu
          anchorEl={payTypesMenuAnchor}
          open={Boolean(payTypesMenuAnchor)}
          onClose={handlePayTypesMenuClose}
        >
          {getFilteredPayTypes().map((payType, index) => (
            <MenuItem key={index} onClick={() => handlePayTypeSelect(payType)}>
              {payType}
            </MenuItem>
          ))}
        </Menu>

        {/* Меню подразделений */}
        <Menu
          anchorEl={restorauntGroupMenuAnchor}
          open={Boolean(restorauntGroupMenuAnchor)}
          onClose={handleRestorauntGroupMenuClose}
        >
          {getFilteredGroups().map((group, index) => (
            <MenuItem key={index} onClick={() => handleRestorauntGroupSelect(group)}>
              {group}
            </MenuItem>
          ))}
        </Menu>
      </StyledContainer>
    </Box>
  );
};

export default Metrics;
