import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

// Найдите элемент корневого рендера
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

// Рендерите ваше приложение с использованием нового API
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
