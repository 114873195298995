import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function EventDetailsDialog({
  showEventDetailsDialog,
  setShowEventDetailsDialog,
  selectedEventType,
  selectedEventDetails,
  ATTRIBUTE_ORDER,
}) {
  // Маппинг типов событий на их русские названия
  const eventTypeTitles = {
    orderDeleted: 'Удаление заказа',
    orderCancelPrecheque: 'Отмена предчека',
    orderPrepayReturned: 'Возврат предоплаты',
    deletedPrintedItems: 'Удаление напечатанных позиций',
  };

  return (
    <Dialog
      open={showEventDetailsDialog}
      onClose={() => setShowEventDetailsDialog(false)}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          backgroundColor: '#2b2b2b',
          color: '#ffffff',
          borderRadius: 4,
          padding: 0,
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.5)',
          position: 'relative',
          height: '90vh',
        },
      }}
      TransitionProps={{
        onEntering: (node) => {
          node.style.transition = 'opacity 0.5s ease, transform 0.5s ease';
          node.style.opacity = '1';
          node.style.transform = 'translateY(0)';
        },
        onExiting: (node) => {
          node.style.transition = 'opacity 0.3s ease, transform 0.3s ease';
          node.style.opacity = '0';
          node.style.transform = 'translateY(-20px)';
        },
      }}
    >
      {/* Заголовок */}
      <DialogTitle
        sx={{
          color: '#ffffff',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '1.25rem',
          padding: 1,
          borderBottom: '1px solid #616161',
          position: 'relative',
          zIndex: 1,
          backgroundColor: '#2a2a2a',
        }}
      >
        {eventTypeTitles[selectedEventType] || 'Детали события'}
      </DialogTitle>

      {/* Основное содержимое диалога */}
      <DialogContent
        sx={{
          paddingTop: 2,
          paddingX: 3,
          overflow: 'auto',
        }}
      >
        <List sx={{ padding: 0 }}>
          {selectedEventDetails.length > 0 ? (
            selectedEventDetails.map((event, index) => (
              <React.Fragment key={event.id || index}>
                <ListItem sx={{ padding: 0 }}>
                  <ListItemText
                    primary={
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}
                      >
                        <AccessTimeIcon sx={{ marginRight: 1, color: '#90caf9' }} />
                        Дата: {event.date.split('T')[0]} | Время: {event.date.split('T')[1].split('+')[0].split('.')[0]}
                      </Typography>
                    }
                  />
                </ListItem>
                {event.attributes && event.attributes.length > 0 ? (
                  <Box
                    sx={{
                      paddingLeft: 2,
                      paddingBottom: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1.5,
                    }}
                  >
                    {event.attributes
                      .sort((a, b) => ATTRIBUTE_ORDER.indexOf(a.name) - ATTRIBUTE_ORDER.indexOf(b.name)) // Сортируем атрибуты
                      .map((attribute, attrIndex) => {
                        let attributeLabel = '';
                        let attributeValue = attribute.value;

                        switch (attribute.name) {
                          case 'isBanquet':
                            attributeLabel = 'Метод';
                            attributeValue = attribute.value === '0E-9' ? 'Без списания' : 'Списание';
                            break;
                          case 'waiter':
                            attributeLabel = 'Официант';
                            break;
                          case 'orderNum':
                            attributeLabel = 'Заказ №';
                            attributeValue = parseInt(attribute.value, 10);
                            break;
                          case 'orderSumAfterDiscount':
                            attributeLabel = 'Сумма заказа';
                            attributeValue = parseFloat(attribute.value).toFixed(2);
                            break;
                          case 'comment':
                            attributeLabel = 'Комментарий';
                            break;
                          case 'user':
                            attributeLabel = 'Авторизовал';
                            break;
                          case 'openTime':
                            attributeLabel = 'Заказ открыт';
                            const [openDate, openTime] = attribute.value.split('T');
                            attributeValue = (
                              <>
                                <Typography variant="body2" color="#ffffff">
                                  Дата: {openDate}
                                </Typography>
                                <Typography variant="body2" color="#ffffff">
                                  Время: {openTime.split('.')[0]}
                                </Typography>
                              </>
                            );
                            break;
                          case 'dishes':
                            attributeLabel = 'Блюдо';
                            break;
                          default:
                            return null;
                        }

                        return (
                          <ListItem key={attrIndex} sx={{ padding: 0 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 1,
                                wordBreak: 'break-word',
                                width: '100%',
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="#90caf9"
                                sx={{ fontWeight: 'bold', minWidth: '100px' }}
                              >
                                {attributeLabel}:
                              </Typography>
                              <Typography variant="body2" color="#ffffff">
                                {attributeValue}
                              </Typography>
                            </Box>
                          </ListItem>
                        );
                      })}
                  </Box>
                ) : (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ paddingLeft: 2, paddingTop: 1 }}
                  >
                    Нет атрибутов для отображения.
                  </Typography>
                )}
                <Divider sx={{ backgroundColor: '#616161', marginTop: 1 }} />
              </React.Fragment>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
              Нет данных для отображения.
            </Typography>
          )}
        </List>
      </DialogContent>

      {/* Кнопка "Закрыть" в самом низу */}
      <DialogActions
        sx={{
          justifyContent: 'center',
          paddingBottom: 1,
          paddingTop: 1,
          position: 'relative',
          width: '100%',
          backgroundColor: '#2b2b2b',
        }}
      >
        <Button
          onClick={() => setShowEventDetailsDialog(false)}
          sx={{
            color: '#90caf9',
            textTransform: 'none',
            fontWeight: 'bold',
            border: '1px solid #90caf9',
            paddingX: 3,
            borderRadius: 8,
          }}
        >
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EventDetailsDialog;
